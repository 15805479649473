import React, { useEffect } from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import ProfileTile from '~2-components/ProfileTile/ProfileTile';
import TileContainer from '~2-components/TileContainer/TileContainer';
import ProfileTileKontent from '~3-data-components/ProfileTileKontent';

const profileTileEg = `import ProfileTile from '~2-components/ProfileTile/ProfileTile';

<ProfileTile
    imgUrl='' // Required
    imgAlt='' // Required
    accredited='' // 'yes' displays accredication logo
    firstName='' // Required
    lastName='' // Required
    position='' // Required
    phone='' // Optional
    email='' // Optional
    linkedIn='' // Optional
    urlSlug='' // Required
/>
`;

const SgProfileTile = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const KontentProfileTiles = useStaticQuery(
        graphql`
            query SGProfileTiles {
                allKontentItemProfile {
                    nodes {
                        system {
                            id
                            type
                        }
                    }
                }
            }
    `);

    const allCMSTiles = KontentProfileTiles.allKontentItemProfile.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Profile Tile</h1>
                    <h2>How to use</h2>
                    <CodeBlock
                        code={profileTileEg}
                    />

                    <h2>Example</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <ProfileTile
                        imgUrl='https://source.unsplash.com/featured/152x152?people'
                        imgAlt='Team member image'
                        accredited='yes'
                        firstName='Bree'
                        lastName='Knoester'
                        position='Managing Partner'
                        phone='(03) 9321 9988'
                        email='bree.knoester@alil.com.au'
                        linkedIn='#'
                        urlSlug='/bree-knoester'
                        urlText='View Profile'
                    />
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>All LIVE Kontent profile tiles</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer>
                        {allCMSTiles.map(tile => {
                            return (
                                <ProfileTileKontent key={tile.system.id} system={tile.system} />
                            );
                        })}
                    </TileContainer>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgProfileTile;
